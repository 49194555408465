<template>
    <v-row no-gutters>
        <v-col>
            <router-view />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import bus from "../store/action_bus";
import Base from "./base";
import Sidebar from "components/sidebar";
import AccessMixin from "mixins/access_helper.js";
import { authHttp } from "@/http";

export default {
    name: "StoreAdmin",
    mixins: [AccessMixin],
    computed: {
        ...mapGetters(["store", "dev", "posProducts", "user", "superuser"]),
    },
    methods: {
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = this.store.storeName;
            }
        },
        ...mapActions(["getActiveStore", "getActiveLiveStore"]),
    },
    watch: {
        "$route.params.store"() {
            this.getActiveStore(this.$route.params.store);
            this.getActiveLiveStore(this.$route.params.store);
            this.showLanguages = false;
            authHttp
                .get(`/api/languages/waypoint/${this.$route.params.store}`)
                .then((res) => {
                    this.showLanguages =
            res.data.languages.filter((el) => el !== "en").length > 0;
                });
            bus.$emit("collapseAllItems");
        },
        store() {
            this.resolvePageTitle();
        },
    },
    components: {
        BasePage: Base,
        Sidebar,
    },
    created() {
        this.resolvePageTitle();
        this.getActiveStore(this.$route.params.store);
        this.showLanguages = false;
        authHttp
            .get(`/api/languages/waypoint/${this.$route.params.store}`)
            .then((res) => {
                this.showLanguages =
          res.data.languages.filter((el) => el !== "en").length > 0;
            });
    },
};
</script>

<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";

.application--wrap {
  min-height: inherit !important;
  max-height: calc(100vh - 108px);
  height: calc(100vh - 108px);
}
.desktopSidebar {
  @include below(sm) {
    display: none;
  }
}

.page-content {
  background-color: $grey_light_2;
  padding-bottom: spacing(xxl);
  border-left: 1px solid $grey_light_1;
  min-height: calc(100vh - 105px);
}

.desktopNav {
  @include below(sm) {
    display: none;
  }
}
</style>
